export interface IPensionBuilder {
    readonly distributions: { [key: string]: IDistribution };

    readonly lastUpdatedOn?: Date;
    readonly preference?: string;
}

export interface IDistribution {
    readonly bad: number;
    readonly median: number;
    readonly good: number;

    readonly outcomes: number[];
}

export const initialPensionBuilder: IPensionBuilder = {
    distributions: {}
}