import { initialRespondent, IRespondent } from './respondent';
import { RespondentActions } from './respondentActions';

export default function appReducer(state: IRespondent = initialRespondent, action: RespondentActions) {
    switch (action.type) {
        case 'respondent/language': {
            return {
                ...state,

                language: action.language
            };
        }
        case 'respondent/set': {
            return {
                ...action.respondent,
                
                failed: false,
                loaded: true,
                loading: false
            };
        }
        case 'respondent/loading': {
            return {
                ...state,

                failed: false,
                loaded: false,
                loading: true
            }
        }
        case 'respondent/failed': {
            return {
                ...state,

                failed: true,
                loaded: false,
                loading: false
            }
        }
        case 'holtAndLaury/setPreference': {
            return {
                // Copy the whole state
                ...state
            }
        }
        default:
            return state;
    }
}