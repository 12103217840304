import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import Authorization from './components/authorization';

class Root extends React.Component<any, any> {
    render() {
        return (
            <Switch>
                <Route path="/login/:id" component={Authorization} />
                <Route component={Authorization} />
            </Switch>
        );
    }
}

export default connect(state => state)(withRouter(Root));