import React from "react";
import { Alert, Button, Card, Container, Form, Spinner, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import configData from "../config.json";
import Display from '../display';
import { IRespondent, IRespondentConnected } from "../respondent";
import { IRespondentFailed, IRespondentLanguage, IRespondentLoading, IRespondentSet } from "../respondentActions";

class Authorization extends React.Component<IRespondentConnected, any> {
    constructor(props: any) {
        super(props);

        this.submitId = this.submitId.bind(this);
        this.getRespondent = this.getRespondent.bind(this);

        if (this.props.match !== undefined && this.props.match.params.id !== undefined) {
            var id = this.props.match.params.id;
            this.getRespondent(id, false);
        }

        if (configData.TEST_PARTICIPANT !== "") {
            this.getRespondent(configData.TEST_PARTICIPANT, false)
        }
    }

    submitId(event: any) {
        // Prevent a page reroute.
        event.preventDefault();
        // Get the actual respondent.
        this.getRespondent(event.target.id.value, true);
    }

    getRespondent(id: string, setLanguage: boolean) {
        console.log("Respondent requested...");

        // Update loading
        this.props.dispatch({
            type: 'respondent/loading',
            id: id
        } as IRespondentLoading);

        // Fetch the respondent model from the storage account.
        let url = setLanguage
            ? configData.API_URL + '/api/UpdateLanguage/' + id + '/' + this.props.language
            : configData.API_URL + '/api/GetModel/' + id;
        fetch(url, {
            method: setLanguage ? 'POST' : 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(
                response => {
                    if (response.status === 200) {
                        // Succesful!
                        response.json().then((data: IRespondent) => {
                            console.log("Respondent received!", data);
                            // Create object from JSON.
                            var respondent = {
                                ...data,

                                loaded: true,
                                failed: false
                            } as IRespondent;

                            // Dispatch update event.
                            this.props.dispatch({
                                type: 'respondent/set',
                                respondent: respondent
                            } as IRespondentSet);

                            // Update to redirect.
                            if (configData.TEST_PAGE !== "") {
                                this.props.history.push(configData.TEST_PAGE);
                            } else {
                                this.props.history.push('/introductie');
                            }
                        });
                    } else {
                        // O-oh!
                        console.log("Respondent not received!", response);
                        // Dispatch update event.
                        this.props.dispatch({
                            type: 'respondent/failed',
                            respondent: {
                                id: this.props.id,

                                loading: false,
                                loaded: false,
                                failed: true
                            }
                        } as IRespondentFailed);
                    }
                },
                error => {
                    console.log("Respondent request failed.", error);
                }
            );
    }

    render() {
        if (this.props.loading) {
            return (
                <Spinner style={{ 'height': '200px', 'width': '200px', 'margin': 'auto' }} animation="border" role="status" variant="info">
                    <span className="sr-only">Gegevens worden geladen...</span>
                </Spinner>
            );
        } else if (this.props.loaded) {
            return (<Display key="display" />);
        } else {
            return (
                <div className="textdiv" style={{ 'maxWidth': '650px' }} >
                    <Form onSubmit={this.submitId}>
                        <Card>
                            <Card.Body>
                                <Container fluid>
                                    <Row style={{ 'paddingBottom': '20px', }}>
                                        <img
                                            src="/images/netherlands.png"
                                            className="centered rounded-circle"
                                            style={{
                                                'border': 'solid 3px ' + (this.props.language === 'nl' ? '#003781' : '#656565'),
                                                'cursor': 'pointer',
                                                'margin': '5px',
                                                'height': '35px',
                                                'width': '35px',
                                                'zIndex': 100,
                                                'position': 'absolute',
                                                'top': '5px',
                                                'left': '5px',
                                                'opacity': this.props.language === 'nl' ? '1' : '0.4',
                                                'filter': 'alpha(opacity=' + (this.props.language === 'nl' ? '100' : '40') + ')', /* msie */
                                                'backgroundColor': '#000000'
                                            }}
                                            onClick={() => this.props.dispatch({
                                                type: 'respondent/language',
                                                language: 'nl'
                                            } as IRespondentLanguage)}
                                        />
                                        <img
                                            src="/images/great-brittain.png"
                                            className="centered rounded-circle"
                                            style={{
                                                'border': 'solid 3px ' + (this.props.language !== 'nl' ? '#003781' : '#656565'),
                                                'cursor': 'pointer',
                                                'margin': '5px',
                                                'height': '35px',
                                                'width': '35px',
                                                'zIndex': 100,
                                                'position': 'absolute',
                                                'top': '5px',
                                                'left': '45px',
                                                'opacity': this.props.language !== 'nl' ? '1' : '0.4',
                                                'filter': 'alpha(opacity=' + (this.props.language !== 'nl' ? '100' : '40') + ')', /* msie */
                                                'backgroundColor': '#000000'
                                            }}
                                            onClick={() => this.props.dispatch({
                                                type: 'respondent/language',
                                                language: 'en'
                                            } as IRespondentLanguage)}
                                        />
                                        <Col>
                                            <img
                                                src="/images/aaa-riskfinance-logo.svg"
                                                className="centered"
                                                style={{ 'maxHeight': 100 }}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                                <div hidden={this.props.language !== 'nl'}>
                                    <p>
                                        In de e-mail of brief van <b>Allianz</b> hebt u een persoonlijke code ontvangen.
                                        Met deze code controleren we of u deze enqu&ecirc;te mag invullen.
                                    </p>
                                    <p style={{ 'marginBottom': '1em' }}>
                                        Vul hier uw <em>code</em> in: <br />
                                    </p>
                                </div>
                                <div hidden={this.props.language !== 'en'}>
                                    <p>
                                        The e-mail from <b>Allianz</b> contains a personal code.
                                        With this code we check if you are eligable to participate in the questionaire.
                                    </p>
                                    <p style={{ 'marginBottom': '1em' }}>
                                        Please enter your <em>code</em> here: <br />
                                    </p>
                                </div>

                                <input type="string" name="id" />
                                <Alert show={this.props.failed} variant='danger' style={{ "marginTop": "15px" }}>
                                    <Container>
                                        <div hidden={this.props.language !== 'nl'}>
                                            <b>
                                                <span className="icon fas fa-exclamation-triangle" style={{ 'marginRight': '10px' }} />
                                                Helaas, er is iets mis gegaan!
                                            </b>
                                            <br />
                                            <p>
                                                Klopt de code die u mee heeft gestuurd?
                                                Heeft u reeds het onderzoek voltooid? Zo nee, en dit blijft fout gaan neem dan contact met ons op.
                                            </p>
                                            <p>
                                                U kunt ons op werkdagen tussen 8.30 uur en 17.00 uur bereiken via 088 577 41 43.
                                            </p>
                                        </div>
                                        <div hidden={this.props.language !== 'en'}>
                                            <b>
                                                <span className="icon fas fa-exclamation-triangle" style={{ 'marginRight': '10px' }} />
                                                Unfortunately, something went wrong!
                                            </b>
                                            <br />
                                            <p>
                                                Is the code you sent correct?
                                                Have you already completed the survey? If not, and this problem continues to occur, please contact us.
                                            </p>
                                            <p>
                                                You can reach us on business days between 8:30 AM and 5:00 PM at 088 577 41 43.
                                            </p>
                                        </div>
                                    </Container>
                                </Alert>
                            </Card.Body>
                            <Card.Footer>
                                <Button className="centered" type="submit">
                                    Ga verder
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Form>
                </div >
            );
        }
    }
}

export default connect((state: IRespondentConnected) => state)(withRouter(Authorization));