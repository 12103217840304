import React from "react";
import { Button, Container, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { IRespondentConnected } from "../respondent";
import './introduction.css';

class IntroductionDisplay extends React.Component<IRespondentConnected, any> {
    render() {
        let lastHoltAndLaury = this.props.holtAndLauries.find(hl => hl.lastUpdatedOn === null);

        console.log(this.props.holtAndLauries);
        // If no Holt and Laury has been touched this is a new entry for the user.
        if (lastHoltAndLaury !== undefined && lastHoltAndLaury.step == 1) {
            return (
                <Container fluid style={{ 'paddingTop': '15px', 'paddingBottom': '15px', 'marginTop': 'auto', 'marginBottom': 'auto' }}>
                    <div className="textdiv" hidden={this.props.language !== 'nl'}>
                        <h1>Welkom!</h1>

                        <p>
                            Fijn dat u de tijd neemt om mee te doen aan het risicopreferentieonderzoek.
                        </p>
                        <p>
                            Over het onderzoek:
                        </p>
                        <ul>
                            <li>U krijgt hierna vier onderdelen met vragen te zien.</li>
                            <li>Er zijn geen goede of foute antwoorden, het gaat om uw mening.</li>
                            <li>Alle antwoorden zijn anoniem.</li>
                            <li>Met de knoppen onderaan de pagina klikt u steeds een vraag verder.</li>
                            <li>U kunt tussendoor stoppen en later weer doorgaan.</li>
                            <li>U kunt eenmaal gegeven antwoorden niet meer aanpassen.</li>
                        </ul>
                        <div>
                            Klik op de knop hieronder om te <em>beginnen</em>.
                        </div>
                        <div>
                            <Button onClick={() => { this.props.history.push('/holtEnLaury'); }}>
                                Beginnen <span style={{ 'marginLeft': '5px' }} className="icon fas fa-forward" />
                            </Button>
                        </div>
                    </div>
                    <div className="textdiv" hidden={this.props.language !== 'en'}>
                        <h1>Welcome!</h1>

                        <p>
                            Thank you for taking the time to participate in this risk preferences survey.
                        </p>
                        <p>
                            About the survey:
                        </p>
                        <ul>
                            <li>It consists of four different parts with questions.</li>
                            <li>There are no right or wrong answers, your opinion is that what matters.</li>
                            <li>All answers are given anonymously.</li>
                            <li>The buttons at the bottom are used to continue to the next step.</li>
                            <li>You can stop always close and then continue at a different moment.</li>
                            <li>Answers can not be changed afterwards.</li>
                        </ul>
                        <div>
                            Click on the button below to <em>start</em>.
                        </div>
                        <div>
                            <Button onClick={() => { this.props.history.push('/holtEnLaury'); }}>
                                Start <span style={{ 'marginLeft': '5px' }} className="icon fas fa-forward" />
                            </Button>
                        </div>
                    </div>
                </Container >
            );

            // Otherwise continue with the next step.
        } else {
            return (<Redirect to="/holtEnLaury" />);
        }
    }
};

export default connect(state => state)(withRouter(IntroductionDisplay));