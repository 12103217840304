import { EventEmitter } from 'events';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Redirect, Route } from 'react-router-dom';
import './display.css';
import configData from "./config.json";
import HoltAndLauryDisplay from './holtAndLaury/holtAndLauryDisplay';
import IntroductionDisplay from './introduction/introductionDisplay';
import OutroDisplay from './outro/outroDisplay';
import PensionBuilderDisplay from './pensionBuilder/pensionBuilderDisplay';
import VragenlijstDisplay from './vragenlijst/vragenlijstDisplay';
import { IRespondentFailed, IRespondentLanguage, IRespondentLoading, IRespondentSet } from './respondentActions';
import { IRespondent, IRespondentConnected } from './respondent';
import verantwoordBeleggenDisplay from './verantwoordBeleggen/verantwoordBeleggenDisplay';

interface DisplayState {
    show: boolean;
    page: number;

    previousPage: boolean;
    nextPage: boolean;
}

class Display extends React.Component<IRespondentConnected, DisplayState> {
    constructor(props: any, context: any) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.eventEmitter = new EventEmitter();
        this.onSave = this.onSave.bind(this);

        this.findPageNumber = this.findPageNumber.bind(this);
        this.navigateNone = this.navigateNone.bind(this);
        this.navigatePreviousPage = this.navigatePreviousPage.bind(this);
        this.navigateNextPage = this.navigateNextPage.bind(this);

        this.pages = [
            '/',
            '/introductie',
            '/holtAndLaury',
            '/pensionBuilder',
            '/verantwoordBeleggen',
            '/vragenlijst'
        ];

        this.state = {
            show: false,
            page: this.findPageNumber(),

            previousPage: false,
            nextPage: false
        };
    }

    componentDidUpdate(prevProps: any, prevState: DisplayState, snapshot: any) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.navigateNone();
            this.setState({ page: this.findPageNumber() });
        }
    }

    findPageNumber(): number {
        return this.pages.findIndex((val: string) => val === this.props.location.pathname);
    }

    eventEmitter: EventEmitter;
    pages: string[];
    onSave(event: any) {
        event.preventDefault();
        console.log("Save on display...");
        this.eventEmitter.emit('save');
        this.handleClose();
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    navigateNone() {
        this.setState({ previousPage: false, nextPage: false });
    }

    navigatePreviousPage() {
        this.setState({ previousPage: true, nextPage: false });
    }

    previousPage() {
        var previousPageAddress = this.pages[Math.max(0, this.state.page - 1)];
        console.log("Goto previous page:", previousPageAddress);
        return <Redirect to={previousPageAddress} />;
    }

    navigateNextPage() {
        this.setState({ previousPage: false, nextPage: true });
    }

    nextPage() {
        var nextPageAddress = this.pages[Math.min(this.pages.length - 1, this.state.page + 1)];
        console.log("Goto next page:", nextPageAddress);
        return <Redirect to={nextPageAddress} />;
    }

    redirect() {
        if (this.state.previousPage) {
            return this.previousPage();
        } else if (this.state.nextPage) {
            return this.nextPage()
        }
    }

    updateLanguage(language: string) {
        console.log("Respondent requested...");

        // Update loading
        this.props.dispatch({
            type: 'respondent/loading',
            id: this.props.id
        } as IRespondentLoading);


        // Fetch the respondent model from the storage account.
        fetch(
            configData.API_URL + '/api/UpdateLanguage/' + this.props.id + '/' + language,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(
                response => {
                    if (response.status === 200) {
                        // Succesful!
                        response.json().then((data: IRespondent) => {
                            console.log("Respondent received!", data);
                            // Dispatch update event.
                            this.props.dispatch({
                                type: 'respondent/set',
                                respondent: data
                            } as IRespondentSet);
                        });
                    } else {
                        // O-oh!
                        console.log("Respondent not received!", response);
                        // Dispatch update event.
                        this.props.dispatch({
                            type: 'respondent/failed',
                            respondent: {
                                id: this.props.id,


                                loading: false,
                                loaded: false,
                                failed: true
                            }
                        } as IRespondentFailed);
                    }
                },
                error => {
                    console.log("Respondent request failed.", error);
                }
            );
    }

    render() {
        return (
            <Container fluid style={{
                'paddingTop': '60px',
                'paddingBottom': '60px',
                'marginTop': 'auto',
                'marginBottom': 'auto'
            }}>
                <img
                    src="/images/netherlands.png"
                    className="centered rounded-circle"
                    style={{
                        'zIndex': 100,
                        'border': 'solid 3px ' + (this.props.language === 'nl' ? '#003781' : '#656565'),
                        'position': 'fixed',
                        'top': '0px',
                        'left': '0px',
                        'cursor': 'pointer',
                        'margin': '5px',
                        'height': '35px',
                        'width': '35px',
                        'opacity': this.props.language == 'nl' ? '1' : '0.4',
                        'filter': 'alpha(opacity=' + (this.props.language == 'nl' ? '100' : '40') + ')', /* msie */
                        'backgroundColor': '#000000'
                    }}
                    onClick={() => this.updateLanguage('nl')}
                />
                <img
                    src="/images/great-brittain.png"
                    className="centered rounded-circle"
                    style={{
                        'zIndex': 100,
                        'border': 'solid 3px ' + (this.props.language !== 'nl' ? '#003781' : '#656565'),
                        'position': 'fixed',
                        'top': '0px',
                        'left': '40px',
                        'cursor': 'pointer',
                        'margin': '5px',
                        'height': '35px',
                        'width': '35px',
                        'opacity': this.props.language !== 'nl' ? '1' : '0.4',
                        'filter': 'alpha(opacity=' + (this.props.language !== 'nl' ? '100' : '40') + ')', /* msie */
                        'backgroundColor': '#000000'
                    }}
                    onClick={() => this.updateLanguage('en')}
                />
                <Route path="/introductie" component={IntroductionDisplay} />
                <Route path="/holtEnLaury" component={HoltAndLauryDisplay} />
                <Route path="/pensionBuilder" component={PensionBuilderDisplay} />
                <Route path="/verantwoordBeleggen" component={verantwoordBeleggenDisplay} />
                <Route path="/vragenlijst" component={VragenlijstDisplay} />
                <Route path="/bedankt" component={OutroDisplay} />
            </Container>
        );
    }
}

export default connect(state => state)(withRouter(Display));