import React from "react";
import { Button, Card } from "react-bootstrap";
import Chart from "react-google-charts";
import './pensioenproduct.css';

interface PensioenproductModel {
    probabilityHigh: number;
    probabilityLow: number;

    outcomeHigh: number;
    outcomeLow: number;
}

interface IPensioenproductProps {
    product: string;
    model: PensioenproductModel;

    language?: string,
    colorHigh?: string,
    colorLow?: string,

    onPreferenceClicked?(): void;
}

interface IPensioenproductState {
    language: string;
    chartWidth: number;
    chartHeight: number;
}

export default class Pensioenproduct extends React.Component<IPensioenproductProps, IPensioenproductState> {
    constructor(props: IPensioenproductProps) {
        super(props);

        this.state = {
            language: this.props.language === undefined ? 'nl' : this.props.language,
            chartWidth: this.getChartWidth(),
            chartHeight: this.getChartHeight()
        }

        window.addEventListener('resize', () => this.setState({
            chartWidth: this.getChartWidth(),
            chartHeight: this.getChartHeight()
        }));
    }

    getChartWidth = () => Math.max(200, Math.min(400, 0.4 * window.innerWidth));
    getChartHeight = () => Math.max(250, Math.min(400, 0.4 * window.innerHeight));

    render() {
        return (
            <Card style={{ 'marginBottom': '10px' }}>
                <Card.Header>
                    Pensio{this.state.language === 'nl' ? 'e' : ''}n  {this.props.product}.
                </Card.Header>
                <Card.Body className="centered">
                    <Chart
                        chartType="PieChart"
                        chartLanguage={this.state.language}
                        loader={
                            this.state.language === 'nl'
                            ? <div>Getallen worden geladen...</div>
                            : <div>Numbers are loading...</div>
                        }
                        data={
                            this.state.language === 'nl'
                            ? [
                                ['Task', 'Pensioenproduct ' + this.props.product],
                                [(100 * this.props.model.probabilityLow).toFixed(0) + '% kans op \u20AC ' + this.props.model.outcomeLow.toLocaleString('nl-NL'), this.props.model.probabilityLow],
                                [(100 * this.props.model.probabilityHigh).toFixed(0) + '% kans op \u20AC ' + this.props.model.outcomeHigh.toLocaleString('nl-NL'), this.props.model.probabilityHigh]
                            ]
                            : [
                                ['Task', 'Pensionproduct ' + this.props.product],
                                [(100 * this.props.model.probabilityLow).toFixed(0) + '% probability of \u20AC ' + this.props.model.outcomeLow.toLocaleString('nl-NL'), this.props.model.probabilityLow],
                                [(100 * this.props.model.probabilityHigh).toFixed(0) + '% probability of \u20AC ' + this.props.model.outcomeHigh.toLocaleString('nl-NL'), this.props.model.probabilityHigh]
                            ]
                        }
                        options={{
                            title: '',
                            legend: 'none',
                            width: this.state.chartWidth,
                            height: this.state.chartHeight,
                            pieSliceText: 'none',
                            pieSliceTextStyle: {
                                fontSize: 15
                            },
                            selectionMode: 'multiple',
                            tooltip: {
                                trigger: 'selection',
                                text: 'none',
                                textStyle: {
                                    fontSize: Math.min(window.innerWidth, window.innerHeight) > 450 ? 15 : 12
                                }
                            },
                            slices: {
                                0: { color: this.props.colorLow ?? 'rgb(191,227,243)' },
                                1: { color: this.props.colorHigh ?? 'rgb(0,142,208)' }
                            }
                        }}
                        chartEvents={[
                            {
                                eventName: "ready",
                                callback({ chartWrapper }) {
                                    console.log("Ready selection...");
                                    var chart = chartWrapper.getChart() as any;
                                    chart.setSelection([{ row: 0 }, { row: 1 }]);
                                }
                            },
                            {
                                eventName: "select",
                                callback({ chartWrapper }) {
                                    console.log("Overwrite selection...");
                                    var chart = chartWrapper.getChart() as any;
                                    chart.setSelection([{ row: 0 }, { row: 1 }]);
                                }
                            }
                        ]}
                    />
                </Card.Body>
                {false && this.props.onPreferenceClicked && <Card.Footer>
                    <Button className="centered" onClick={this.props.onPreferenceClicked}>
                        Ik kies voor {this.props.product}.
                    </Button>
                </Card.Footer>}
            </Card >
        );
    }
};