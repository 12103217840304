import React from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import RangeSlider from "../components/rangeSlider";
import configData from "../config.json";
import { IDistribution } from "../pensionBuilder/pensionBuilderModels";
import { IRespondent, IRespondentConnected } from "../respondent";
import { IRespondentFailed, IRespondentLoading, IRespondentSet } from "../respondentActions";

interface IVragenlijstDisplay {
    introduction: boolean;
    distribution: IDistribution;
}

class VragenlijstDisplay extends React.Component<IRespondentConnected, IVragenlijstDisplay> {
    constructor(props: IRespondentConnected) {
        super(props)

        var currentQuestion = this.props.questions.find(q => q.lastUpdatedOn === null);
        if (currentQuestion !== undefined && currentQuestion.step === 1) {
            this.state = {
                introduction: true,
                distribution: this.props.pensionBuilder.distributions[this.props.pensionBuilder.preference ?? "Mid"]
            }
        } else {
            this.state = {
                introduction: false,
                distribution: this.props.pensionBuilder.distributions[this.props.pensionBuilder.preference ?? "Mid"]
            }
        }

    }

    // On confirmation we no longer need to show the introduction.
    // On help we should be able to show it again.
    showIntroduction = (introduction: boolean) => this.setState({ introduction: introduction });

    answerMultipleChoiceQuestion(event: any, step: number) {
        event.preventDefault();

        var selectedAnswer = document.querySelector('input[name="multipleChoice"]:checked');
        if (selectedAnswer !== null) {
            var answerIx = this.props.questions[step - 1]
                .possibleAnswers?.findIndex((val: string, index: number) => 'multipleChoice_' + index == selectedAnswer?.id) ?? -1

            this.updateQuestion(step, answerIx);
        }
    }

    answerOpenQuestion(event: any, step: number) {
        event.preventDefault();
        this.updateQuestion(step, event.target.range.value);
    }

    uitkomst(outcome: number, color?: string) {
        return (
            <h4 style={{ textAlign: 'right', color: color }}>
                &euro;{Math.round(outcome).toLocaleString('nl-NL') + ',-'}
            </h4>
        );
    }

    updateQuestion(step: number, answer: number) {
        console.log("Respondent requested...");
        
        // Update loading
        this.props.dispatch({
            type: 'respondent/loading',
            id: this.props.id
        } as IRespondentLoading);


        // Fetch the respondent model from the storage account.
        fetch(
            configData.API_URL + '/api/UpdateQuestion/' + this.props.id + '/' + step + '/' + answer,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(
                response => {
                    if (response.status === 200) {
                        // Succesful!
                        response.json().then((data: IRespondent) => {
                            console.log("Respondent received!", data);
                            // Dispatch update event.
                            this.props.dispatch({
                                type: 'respondent/set',
                                respondent: data
                            } as IRespondentSet);
                        });
                    } else {
                        // O-oh!
                        console.log("Respondent not received!", response);
                        // Dispatch update event.
                        this.props.dispatch({
                            type: 'respondent/failed',
                            respondent: {
                                id: this.props.id,

                                loading: false,
                                loaded: false,
                                failed: true
                            }
                        } as IRespondentFailed);
                    }
                },
                error => {
                    console.log("Respondent request failed.", error);
                }
            );
    }

    render() {
        // Check what the current step is for questions.
        let content;
        var currentQuestionIx = this.props.questions.findIndex(q => q.lastUpdatedOn === null);
        var currentQuestion = this.props.language === 'nl' ? this.props.questions[currentQuestionIx] :this.props.questionsEng[currentQuestionIx]

        // If all answers have ben already updated...
        // We can not find any and redirect to Pensionbuilder!
        if (currentQuestion === undefined) {
            return (<Redirect to='/bedankt' />);
            // Otherwise, let's go!
        } else {
            if (this.state.introduction) {
                content = this.props.language === 'nl'
                ? <div>
                    <h1>Kort kwalitatief onderzoek</h1>
                    <h5>Onderdeel 4 van 4</h5>
                    <p>
                        In het laatste deel van dit onderzoek stellen we u enkele achtergrondvragen. Deze vragen zijn bedoeld om onze deelnemers beter te leren kennen en ons beleggingsbeleid hier beter op te laten aansluiten. Hierna volgt eerst een korte introductie.
                    </p>
                    <Button className="centered" onClick={() => this.showIntroduction(false)}>
                        Ga verder
                    </Button>
                  </div>
                : <div>
                    <h1>Short qualitative survey</h1>
                    <h5>Part 4 of 4</h5>
                    <p>
                        In the last part of this survey we ask you some background questions. These questions are meant for us to better get to know our participants and be able to align our investment policy with this information.
                    </p>
                    <Button className="centered" onClick={() => this.showIntroduction(false)}>
                        Ga verder
                    </Button>
                </div>;
            } else {
                // Store step to prevent issues later on with the button.
                var step = currentQuestion.step;

                // If we have multiple choice aswers, we should show them.
                if (currentQuestion.possibleAnswers !== null) {
                    var possibleAnswers = currentQuestion.possibleAnswers ?? [];
                    var answers = possibleAnswers.map((answer, ix) =>
                        <Form.Check
                            type="radio"
                            label={answer}
                            name="multipleChoice"
                            key={"multipleChoice" + ix}
                            id={"multipleChoice_" + ix}
                        />);

                    content =
                        <Container>
                            <Row style={{ 'marginBottom': '10px' }}>
                                <Col>
                                    <h5 style={{ 'margin': '0px auto' }} hidden={this.props.language !== 'nl'}>
                                        Stap {step} van {this.props.questions.length}
                                    </h5>
                                    <h5 style={{ 'margin': '0px auto' }} hidden={this.props.language !== 'en'}>
                                        Step {step} of {this.props.questions.length}
                                    </h5>
                                </Col>
                                <Col>
                                    <Button
                                        className="centered"
                                        style={{ 'margin': '0 0 0 auto ' }}
                                        onClick={() => this.showIntroduction(true)}
                                    >
                                        <span className="icon fas fa-info-circle" style={{ 'marginRight': '5px' }} />
                                        {this.props.language === 'nl' ? "Terug naar uitleg" : "Show explanation"}
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form
                                        className="display-centered"
                                        onSubmit={(event: any) => this.answerMultipleChoiceQuestion(event, step)}
                                    >
                                        <Card>
                                            <Card.Body>
                                                <p dangerouslySetInnerHTML={{ __html: currentQuestion.originalQuestion }} />
                                                <fieldset>
                                                    <Form.Group as={Row}>
                                                        <Col>
                                                            {answers}
                                                        </Col>
                                                    </Form.Group>
                                                </fieldset>
                                            </Card.Body>
                                            <Card.Footer>
                                                <Button className="centered" type="submit">
                                                    Bevestig
                                                </Button>
                                            </Card.Footer>
                                        </Card>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>;
                } else {
                    let range = currentQuestion.range ?? { "item1": -1, "item2": 1 };
                    let openQuestionContent;

                    // Dit is eigenlijk niet zo lekker...
                    // De laatste vraag is zo hardcoded geworden...
                    if (currentQuestion.step === 8) {
                        let previousQuestion = this.props.questions[5];

                        // Alleen door als antwoord op laatste vraag niet NEE was.
                        if (previousQuestion.answered !== undefined && previousQuestion.possibleAnswers !== undefined
                            && previousQuestion.possibleAnswers[previousQuestion.answered].toLowerCase() !== "nee") {
                            openQuestionContent = <div>
                                <hr />
                                Als het beter gaat
                                {this.uitkomst(this.state.distribution.good, 'green')}

                                Als het loopt zoals verwacht
                                {this.uitkomst(this.state.distribution.median)}

                                Als het minder gaat
                                {this.uitkomst(this.state.distribution.bad, 'red')}
                                <hr />
                                <RangeSlider
                                    id="range"
                                    min={Math.floor(Math.max(0, this.state.distribution.bad) / 10) * 10}
                                    max={this.state.distribution.median}
                                    initialValue={Math.round(this.state.distribution.median)}
                                    valueDecorator={(value: number) => 'De 100% zekere pensioenuitkering moet dan minimaal gelijk zijn aan \u20ac' + (value + 3).toLocaleString('nl-NL')}
                                />
                            </div>;
                        } else {
                            openQuestionContent = <Redirect to="/bedankt" />
                        }
                    } else {
                        openQuestionContent = <RangeSlider
                            id="range"
                            min={range.item1}
                            max={range.item2}
                            initialValue={Math.round((range.item1 + range.item2) / 2)}
                            valueDecorator={(value: number) => (parseFloat(value.toString()) + 3).toString()}
                        />;
                    }

                    content =
                        <Container>
                            <Row style={{ 'marginBottom': '10px' }}>
                                <Col>
                                <h5 style={{ 'margin': '0px auto' }} hidden={this.props.language !== 'nl'}>
                                        Stap {step} van {this.props.questions.length}
                                    </h5>
                                    <h5 style={{ 'margin': '0px auto' }} hidden={this.props.language !== 'en'}>
                                        Step {step} of {this.props.questions.length}
                                    </h5>
                                </Col>
                                <Col>
                                    <Button
                                        className="centered"
                                        style={{ 'margin': '0 0 0 auto ' }}
                                        onClick={() => this.showIntroduction(true)}
                                    >
                                        <span className="icon fas fa-info-circle" style={{ 'marginRight': '5px' }} />
                                        {this.props.language === 'nl' ? "Terug naar uitleg" : "Show explanation"}
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form
                                        className="display-centered"
                                        onSubmit={(event: any) => this.answerOpenQuestion(event, step)}
                                    >
                                        <Card>
                                            <Card.Body>
                                                <p>
                                                    {currentQuestion.originalQuestion}
                                                </p>
                                                {openQuestionContent}
                                            </Card.Body>
                                            <Card.Footer>
                                                <Button className="centered" type="submit">
                                                {this.props.language === 'nl' ? "Bevestig" : "Confirm"}
                                                </Button>
                                            </Card.Footer>
                                        </Card>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>;
                }
            }

            return (
                <div className="centered automargin" style={{ 'maxWidth': '650px' }} >
                    {content}
                </div >
            );
        }
    }
};

export default connect(state => state)(withRouter(VragenlijstDisplay));