import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { IRespondentConnected } from "../respondent";

class OutroDisplay extends React.Component<IRespondentConnected, any> {
    render() {
        return (this.props.language === 'nl'
            ? <div className="textdiv" style={{ 'maxWidth': '650px' }}>
                <h1>Bedankt voor het invullen!</h1>
                <p>
                    Bedankt voor uw deelname aan dit onderzoek. Binnenkort koppelen wij een samenvatting van de resultaten naar u terug.
                </p>
                <p>
                    Voor informatie over uw verwachte pensioenuitkering bij Allianz, kunt u onze pensioenplanner of mijnpensioenoverzicht.nl raadplegen.
                </p>
                <p>
                    Daarnaast kunt u ons bij vragen tijdens kantooruren bereiken op telefoonnummer 088 577 41 43.
                </p>
                <p><i>
                    De pensioenbedragen getoond in dit onderzoek zijn zo zorgvuldig mogelijk berekend, onder andere aan de hand van uw persoonlijke pensioensituatie. De mogelijkheid bestaat dat deze bedragen afwijken van de bedragen die het Allianz recent op andere wijze - bijvoorbeeld via het Uniform Pensioenoverzicht of de pensioenplanner - aan u heeft gecommuniceerd. U kunt aan de in dit onderzoek getoonde bedragen geen rechten ontlenen. Het pensioenreglement is bepalend voor de hoogte van uw pensioen.
                </i></p>
                <p><i>
                    Allianz voert dit onderzoek in samenwerking met een externe partij (Triple A) uit. Allianz heeft hiervoor op anonieme basis gegevens aan Triple A beschikbaar gesteld. Triple A kan deze gegevens dus niet herleiden tot u als individuele (gepensioneerde) deelnemer. De gegevens worden door Triple A uitsluitend gebruikt in het kader van dit onderzoek.
                </i></p>
            </div>
            : <div className="textdiv" style={{ 'maxWidth': '650px' }}>
                <h1>Thank you for participating!</h1>
                <p>
                    Thank you for the participation. We will soon provide you with a summary of the results.
                </p>
                <p>
                    For more information about your expected pension at Allianz, you can view our pensionplanner or visit mijnpensionoverzicht.nl.
                </p>
                <p>
                    Additionally, you can reach us for any inquiries during office hours at telephone number 088 577 41 43.
                </p>
                <p><i>
                    The pension amounts shown in this study have been calculated as accurately as possible, based on your personal pension situation among other factors. There is a possibility that these amounts may differ from the amounts that the pension fund has recently communicated to you through other means, such as the Uniform Pension Overview or the pension planner. You cannot derive any rights from the amounts shown in this study. The pension regulations determine the amount of your pension.
                </i></p>
                <p><i>
                    Allianz is conducting this study in collaboration with an external party (Triple A). The pension fund has provided anonymous data to Triple A for this purpose. Triple A cannot trace these data back to you as an individual (retired) participant. The data will be used by Triple A solely for the purpose of this study.
                </i></p>
            </div>
        );
    }
};

export default connect(state => state)(withRouter(OutroDisplay));