import { DispatchProp } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { IHoltAndLauryModel } from "./holtAndLaury/holtAndLauryModels";
import { initialPensionBuilder, IPensionBuilder } from "./pensionBuilder/pensionBuilderModels";
import { RespondentActions } from "./respondentActions";
import { ISociallyResponsibleInvestingModel, initialSociallyResponsibleInvesting } from "./verantwoordBeleggen/verantwoordBeleggenModels";
import { IQuestionModel } from "./vragenlijst/questionModels";

export interface IRespondent {
    readonly failed: boolean;
    readonly loaded: boolean;
    readonly loading: boolean;

    readonly id: string;
    readonly description: string;
    readonly language: string;

    readonly holtAndLauries: IHoltAndLauryModel[];
    readonly pensionBuilder: IPensionBuilder;
    readonly sociallyResponsibleInvesting: ISociallyResponsibleInvestingModel;
    readonly questions: IQuestionModel[];
    readonly questionsEng: IQuestionModel[];
}

export interface IRespondentConnected extends IRespondent,
    RouteComponentProps<any>,
    DispatchProp<RespondentActions> {
}

export const initialRespondent: IRespondent = {
    failed: false,
    loaded: false,
    loading: false,

    id: "",
    description: "",
    language: "nl",

    holtAndLauries: [],
    pensionBuilder: initialPensionBuilder,
    sociallyResponsibleInvesting: initialSociallyResponsibleInvesting,
    questions: [],
    questionsEng: []
}