export interface ISociallyResponsibleInvestingModel {
    readonly higherCosts: number;
    readonly acceptHigherCosts?: boolean;

    readonly higherRiskUpwards: number;
    readonly higherRiskDownwards: number;
    readonly acceptHigherRisks?: boolean;

    readonly lastUpdatedOn?: Date;
}

export const initialSociallyResponsibleInvesting: ISociallyResponsibleInvestingModel = {
    higherCosts: 0.005,

    higherRiskUpwards: 0.025,
    higherRiskDownwards: -0.010
}