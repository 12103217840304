import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import configData from "../config.json";
import { IDistribution } from "../pensionBuilder/pensionBuilderModels";
import { IRespondent, IRespondentConnected } from "../respondent";
import { IRespondentFailed, IRespondentLoading, IRespondentSet } from "../respondentActions";

interface IVerantwoordBeleggenState {
    introduction: boolean;
    distribution: IDistribution;
}

const questionTypes: string[] = ["HigherCosts", "HigherRisks"];

class VerantwoordBeleggenDisplay extends React.Component<IRespondentConnected, IVerantwoordBeleggenState> {
    constructor(props: IRespondentConnected) {
        super(props)

        this.state = {
            introduction: this.props.sociallyResponsibleInvesting.acceptHigherCosts === null,
            distribution: this.props.pensionBuilder.distributions[this.props.pensionBuilder.preference ?? "Mid"]
        }
    }


    currentQuestion = () => {
        if (this.props.sociallyResponsibleInvesting.acceptHigherCosts === null) {
            return questionTypes[0];
        } else {
            return questionTypes[1];
        }
    };

    // On confirmation we no longer need to show the introduction.
    // On help we should be able to show it again.
    showIntroduction = (introduction: boolean) => this.setState({ introduction: introduction });

    uitkomst(outcome: number, color?: string) {
        return (
            <h4 style={{ textAlign: 'right', color: color }}>
                &euro;{Math.round(outcome).toLocaleString('nl-NL') + ',-'}
            </h4>
        );
    }

    // Fetch the respondent model from the storage account.
    savePreference = (preference: boolean) => {
        console.log("Respondent requested...");

        // Update loading
        this.props.dispatch({
            type: 'respondent/loading',
            id: this.props.id
        } as IRespondentLoading);

        // Fetch the respondent model from the storage account.
        fetch(
            configData.API_URL + '/api/UpdateSociallyResponsibleInvesting/' + this.props.id + '/' + this.currentQuestion() + '/' + preference,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(
                response => {
                    if (response.status === 200) {
                        // Succesful!
                        response.json().then((data: IRespondent) => {
                            console.log("Respondent received!", data);
                            // Dispatch update event.
                            this.props.dispatch({
                                type: 'respondent/set',
                                respondent: data
                            } as IRespondentSet);
                        });
                    } else {
                        // O-oh!
                        console.log("Respondent not received!", response);
                        // Dispatch update event.
                        this.props.dispatch({
                            type: 'respondent/failed',
                            respondent: {
                                id: this.props.id,

                                loading: false,
                                loaded: false,
                                failed: true
                            }
                        } as IRespondentFailed);
                    }
                },
                error => {
                    console.log("Respondent request failed.", error);
                }
            );
    }

    render() {
        // Easy of life initialization of some variables.
        let content;
        let distribution = this.state.distribution;
        let sri = this.props.sociallyResponsibleInvesting;

        if (this.state.introduction) {
            // Uitleg.
            content = this.props.language === 'nl'
                ? <div>
                    <h1>Verantwoord beleggen</h1>
                    <h5>Onderdeel 3 van 4</h5>
                    <p>
                        Maatschappelijk verantwoord beleggen wordt steeds populairder. Hierbij wordt er niet alleen gekeken naar het beoogde rendement, maar ook naar de gevolgen voor <em>mens en natuur</em>.
                    </p>
                    <p>
                        Om te onderzoeken hoe hierover gedacht wordt, volgen er hierna keuzes waarbij maatschappelijk verantwoord beleggen mogelijk een <em>impact heeft op uw pensioen</em>. Hier kunt u vervolgens aangeven wat uw voorkeur heeft, verantwoord beleggen of regulier beleggen?
                    </p>
                    <Button onClick={() => this.showIntroduction(false)}>
                        Start <span style={{ 'marginLeft': '5px' }} className="icon fas fa-forward" />
                    </Button>
                </div>
                : <div>
                    <h1>Sustainable investing</h1>
                    <h5>Part 3 of 4</h5>
                    <p>
                        Socially responsible investing is becoming increasingly popular. In this approach, not only the intended financial returns are considered, but also the impacts on <em>people and nature</em>.
                    </p>
                    <p>
                        To explore the opinions on this matter, the following choices will be presented, where socially responsible investing may have an <em>impact on your pension</em>. You can then indicate your preference between responsible investing or conventional investing.
                    </p>
                    <Button onClick={() => this.showIntroduction(false)}>
                        Start <span style={{ 'marginLeft': '5px' }} className="icon fas fa-forward" />
                    </Button>
                </div>;

            // Question 1.
        } else if (this.props.sociallyResponsibleInvesting.acceptHigherCosts === null) {
            content = <div>
                <Container className="centered">
                    <Row style={{ 'marginBottom': '10px' }}>
                        <Col>
                            <h5 style={{ 'margin': '0px auto' }}>
                                {this.props.language === 'nl' ? "Verantwoord beleggen" : "Sustainable investing"}
                            </h5>
                        </Col>
                        <Col>
                            <Button
                                className="centered"
                                style={{ 'margin': '0 0 0 auto ' }}
                                onClick={() => this.showIntroduction(true)}
                            >
                                <span className="icon fas fa-info-circle" style={{ 'marginRight': '5px' }} />
                                {this.props.language === 'nl' ? "Terug naar uitleg" : "Show explanation"}
                            </Button>
                        </Col>
                    </Row>
                </Container>
                <div hidden={this.props.language !== 'nl'}>
                    <h1>Hogere kosten</h1>
                    <p>
                        Stel dat maatschappelijk verantwoord beleggen meer kosten met zich mee brengt. Dit betekent dat er in totaal minder rendement behaald wordt op de beleggingen. Uw pensioen zal daardoor lager uit komen.
                    </p>
                    <p>
                        Bent u in dit geval bereid extra kosten te accepteren voor verantwoord beleggen? Uw keuze kunt onderaan de pagina opgeven.
                    </p>
                </div>
                <div hidden={this.props.language !== 'en'}>
                    <h1>Higher costs</h1>
                    <p>
                        Suppose that socially responsible investing incurs additional costs. This means that the total investment returns will be lower. As a result, your pension will be lower.
                    </p>
                    <p>
                        Are you willing to accept additional costs for responsible investing in this case? You can indicate your choice at the bottom of the page.
                    </p>
                </div>
                <Container fluid style={{ 'marginTop': '10px' }}>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>
                                    {this.props.language === 'nl' ? "Verantwoord beleggen" : "Sustainable investing"}
                                </Card.Header>
                                <Card.Body>
                                    {this.props.language === 'nl'
                                        ? "Als het beter gaat"
                                        : "If it goes better than expected"}
                                    {this.uitkomst(distribution.good * (1 - sri.higherCosts), 'green')}

                                    {this.props.language === 'nl'
                                        ? "Als het loopt zoals verwacht"
                                        : "If it goes as expected"}
                                    {this.uitkomst(distribution.median * (1 - sri.higherCosts))}

                                    {this.props.language === 'nl'
                                        ? "Als het minder gaat"
                                        : "If it goes worse than expected"}
                                    {this.uitkomst(distribution.bad * (1 - sri.higherCosts), 'red')}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>
                                    {this.props.language === 'nl' ? "Normaal beleggen" : "Regular investing"}
                                </Card.Header>
                                <Card.Body>
                                    {this.props.language === 'nl'
                                        ? "Als het beter gaat"
                                        : "If it goes better than expected"}
                                    {this.uitkomst(distribution.good, 'green')}

                                    {this.props.language === 'nl'
                                        ? "Als het loopt zoals verwacht"
                                        : "If it goes as expected"}
                                    {this.uitkomst(distribution.median)}

                                    {this.props.language === 'nl'
                                        ? "Als het minder gaat"
                                        : "If it goes worse than expected"}
                                    {this.uitkomst(distribution.bad, 'red')}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{ 'marginBottom': '15px' }}>
                        <Col>
                            <Button className="centered" onClick={() => this.savePreference(true)}>
                                {this.props.language === 'nl' ? "Ja, ik kies verantwoord beleggen." : "Yes, I choose sustainable investing."}
                            </Button>
                        </Col>
                        <Col>
                            <Button className="centered" onClick={() => this.savePreference(false)}>
                                {this.props.language === 'nl' ? "Nee, ik kies normaal beleggen." : "No, I choose regular investing."}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>;

            // Question 2.
        } else if (this.props.sociallyResponsibleInvesting.acceptHigherRisks === null) {
            content = <div>
                <Container className="centered">
                    <Row style={{ 'marginBottom': '10px' }}>
                        <Col>
                            <h5 style={{ 'margin': '0px auto' }}>{this.props.language === 'nl' ? "Verantwoord beleggen" : "Sustainable investing"}</h5>
                        </Col>
                        <Col>
                            <Button
                                className="centered"
                                style={{ 'margin': '0 0 0 auto ' }}
                                onClick={() => this.showIntroduction(true)}
                            >
                                <span className="icon fas fa-info-circle" style={{ 'marginRight': '5px' }} />
                                {this.props.language === 'nl' ? "Terug naar uitleg" : "Show explanation"}
                            </Button>
                        </Col>
                    </Row>
                </Container>
                <div hidden={this.props.language !== 'nl'}> 
                    <h1>Meer risico</h1>
                    <p>
                        Maatschappelijk verantwoord beleggen is een relatief nieuw begrip in de financi&euml;le markten. Stel dat de risico's bij maatschappelijk verantwoord beleggen hoger zijn. Dit betekent dat uw pensioen in sommige gevallen hoger uit kan komen, maar in andere gevallen lager uit kan komen.
                    </p>
                    <p>
                        Bent u bereid extra risico te accepteren voor verantwoord beleggen? Uw keuze kunt onderaan de pagina opgeven.
                    </p>
                </div>
                <div hidden={this.props.language !== 'en'}>
                    <h1>More risk</h1>
                    <p>
                        Socially responsible investing is a relatively new concept in financial markets.
                        Suppose the risks associated with socially responsible investing are higher.
                        This means that in some cases, your pension may yield higher returns, but in other cases, it may yield lower returns.
                    </p>
                    <p>
                        Are you willing to accept additional risk for responsible investing? You can indicate your choice at the bottom of the page.
                    </p>
                </div>
                <Container fluid style={{ 'marginTop': '10px' }}>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>
                                    {this.props.language === 'nl' ? "Verantwoord beleggen" : "Sustainable investing"}
                                </Card.Header>
                                <Card.Body>
                                    <div>
                                        {this.props.language === 'nl'
                                            ? "Als het beter gaat"
                                            : "If it goes better than expected"}
                                        {this.uitkomst(distribution.good * (1 + sri.higherRiskUpwards), 'green')}
                                    </div>
                                    <div>
                                        {this.props.language === 'nl'
                                            ? "Als het loopt zoals verwacht"
                                            : "If it goes as expected"}
                                        {this.uitkomst(distribution.median)}
                                    </div>
                                    <div>
                                        {this.props.language === 'nl'
                                            ? "Als het minder gaat"
                                            : "If it goes worse than expected"}
                                        {this.uitkomst(distribution.bad * (1 - sri.higherRiskDownwards), 'red')}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>
                                    {this.props.language === 'nl' ? "Normaal beleggen" : "Regular investing"}
                                </Card.Header>
                                <Card.Body>
                                    <div>
                                        {this.props.language === 'nl'
                                            ? "Als het beter gaat"
                                            : "If it goes better than expected"}
                                        {this.uitkomst(distribution.good, 'green')}
                                    </div>
                                    <div>
                                        {this.props.language === 'nl'
                                            ? "Als het loopt zoals verwacht"
                                            : "If it goes as expected"}
                                        {this.uitkomst(distribution.median)}
                                    </div>
                                    <div>
                                        {this.props.language === 'nl'
                                            ? "Als het minder gaat"
                                            : "If it goes worse than expected"}
                                        {this.uitkomst(distribution.bad, 'red')}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{ 'marginBottom': '15px' }}>
                        <Col>
                            <Button className="centered" onClick={() => this.savePreference(true)}>
                                {this.props.language === 'nl' ? "Ja, ik kies verantwoord beleggen." : "Yes, I choose sustainable investing."}
                            </Button>
                        </Col>
                        <Col>
                            <Button className="centered" onClick={() => this.savePreference(false)}>
                                {this.props.language === 'nl' ? "Nee, ik kies normaal beleggen." : "No, I choose regular investing."}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>;
        } else {
            content = <Redirect to="/vragenlijst" />;
        }

        return (
            <div className="centered automargin" style={{ 'maxWidth': '650px' }} >
                {content}
            </div >
        );
    }
};

export default connect(state => state)(withRouter(VerantwoordBeleggenDisplay));