//import "core-js/stable";
//import "regenerator-runtime/runtime";

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './style.css';

import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import appReducer from './app/appReducer';
import Root from './app/root';
import './style.css';


const store = createStore(appReducer, applyMiddleware(thunk));

const rootEl = document.getElementById('layout_container');
const history = createBrowserHistory();

const render = () => ReactDOM.render(
    <Provider store={store as any}>
        <Router history={history}>
            <Root />
        </Router>
    </Provider>,
    rootEl
)

render()
store.subscribe(render)