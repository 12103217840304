import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Chart from "react-google-charts";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import configData from "../config.json";
import { IRespondent, IRespondentConnected } from "../respondent";
import { IRespondentFailed, IRespondentLoading, IRespondentSet } from "../respondentActions";
import exampleDistribution from "./exampleDistribution.json";
import './pensionBuilderDisplay.css';

interface IHoltAndLauryDisplayState {
    introduction: number;
    chartSize: number;

    preference: number;

    histogram: any[][];
    histogramHorizontalAxisMin: number;
    histogramHorizontalAxisMax: number;
    ticks: number[];
}

const preferences: string[] = ["Low", "LowMid", "Mid", "MidHigh", "High"];

class PensionBuilderDisplay extends React.Component<IRespondentConnected, IHoltAndLauryDisplayState> {
    constructor(props: IRespondentConnected) {
        super(props);

        // To determine the dimensions of the histogram.
        // First determine the lowest and highest values in the distribution.
        var minOutcome = Infinity;
        var maxOutcome = -Infinity;
        for (let key in this.props.pensionBuilder.distributions) {
            minOutcome = Math.min(minOutcome, Math.min.apply(Math, this.props.pensionBuilder.distributions[key].outcomes));
            maxOutcome = Math.max(maxOutcome, Math.max.apply(Math, this.props.pensionBuilder.distributions[key].outcomes));
        }

        var chartSize = this.getChartSize();
        var noTicks = Math.max(3, Math.floor(chartSize / 70));
        var deltaTick = (maxOutcome - minOutcome) / noTicks;

        var ticks: number[] = [];
        for (var i = 0; i <= noTicks; i++) {
            var tick = Math.round((minOutcome + i * deltaTick) / 10) * 10;
            ticks.push(tick);
        }

        // Always start with the introduction.
        // Regardless if it was already started.
        // Initalize everything...                
        var outcomes = this.props.pensionBuilder.distributions[preferences[2]].outcomes;
        var histogram = this.createHistogram(outcomes, minOutcome, maxOutcome);

        let histogramHorizontalAxisMin = Math.max(0, minOutcome - 0.55 * histogram.delta);
        let histogramHorizontalAxisMax = maxOutcome + 0.55 * histogram.delta;
        
        var newOutcomes: number[] = [];
        for (var i = 0; i < outcomes.length; i++) {
            if (outcomes[i] > histogramHorizontalAxisMin && outcomes[i] < histogramHorizontalAxisMax) {
                newOutcomes.push(outcomes[i]);
            }
        }
        
        histogram = this.createHistogram(newOutcomes, histogramHorizontalAxisMin, histogramHorizontalAxisMax);

        
        this.state = {
            introduction: 0,
            chartSize: chartSize,

            preference: 2,
            histogram: histogram.histogram,

            histogramHorizontalAxisMin: histogramHorizontalAxisMin,
            histogramHorizontalAxisMax: histogramHorizontalAxisMax,

            ticks: ticks
        }
    }

    getChartSize = () => Math.max(250, Math.min(0.5 * window.innerHeight, 0.8 * window.innerWidth));

    createHistogram = (outcomes: number[], minOutcome: number, maxOutcome: number) => {
        var nBuckets = 100;
        var delta = (maxOutcome - minOutcome) / nBuckets;

        var outcomesPerBucket = outcomes.length / nBuckets;
        var histogram: any[][] = this.props.language === 'nl'
            ? [['Bedrag', 'Gemiddelde uitkering']]
            : [['Amount', 'Average pension']];


        console.log("delta: ", delta, ". maxOutcome:", maxOutcome);

        // Start sorting everything.
        // Provide an (a,b) => a - b sort.
        var sortedOutcomes = outcomes
            .sort((a, b) => a - b)
            .map(o => ({
                bucket: Math.floor((o - minOutcome) / delta),
                outcome: o
            }));

        // Initialize bucket array.
        var buckets: number[] = [];
        for (var i = 0; i < nBuckets; i++) {
            buckets.push(0);
        }

        // Count them all!
        for (var i = 0; i < sortedOutcomes.length; i++) {
            buckets[sortedOutcomes[i].bucket]++;
        }

        var remainder = 0;
        for (var i = 0; i < nBuckets; i++) {
            var from = Math.floor((minOutcome + delta * i));
            var to = Math.ceil(from + delta);

            // If we have looped through enough values create the bucket.
            var noBucketsInTick = remainder + buckets[i] / outcomesPerBucket;
            var remainder = noBucketsInTick - Math.floor(noBucketsInTick);
            for (var j = 1; j <= noBucketsInTick; j++) {
                var message = this.props.language === 'nl'
                    ? 'In 1% van de gevallen valt de pensioenuitkomst tussen de \u20AC' + Math.round(from).toLocaleString('nl-NL') + ' en \u20AC' + Math.round(to).toLocaleString('nl-NL')
                    : 'In 1% of the cases the pension will be within the range of \u20AC' + Math.round(from).toLocaleString('nl-NL') + ' and \u20AC' + Math.round(to).toLocaleString('nl-NL');

                // Push the average value.
                histogram.push([message, from]);
            }
        }

        return { "delta": delta, "histogram": histogram };
    };

    // On confirmation we no longer need to show the introduction.
    // On help we should be able to show it again.
    showIntroduction = (introduction: boolean) => {
        window.scrollTo(0, 0);
        this.setState({ introduction: introduction ? 1 : 0 });
    }

    backIntroduction = () => {
        window.scrollTo(0, 0);
        this.setState({ introduction: this.state.introduction - 1 });
    }

    nextIntroduction = () => {
        window.scrollTo(0, 0);
        var newIntro = this.state.introduction + 1;
        this.setState({ introduction: newIntro > 3 ? 0 : newIntro });
    }


    changePreference(riskier: boolean) {
        let newPreference;
        if (riskier) {
            newPreference = Math.min(preferences.length - 1, this.state.preference + 1);
        } else {
            newPreference = Math.max(0, this.state.preference - 1);
        }

        var outcomes = this.props.pensionBuilder.distributions[preferences[newPreference]].outcomes;
        var newOutcomes: number[] = [];
        for (var i = 0; i < outcomes.length; i++) {
            if (outcomes[i] > this.state.histogramHorizontalAxisMin && outcomes[i] < this.state.histogramHorizontalAxisMax) {
                newOutcomes.push(outcomes[i]);
            }
        }

        this.setState({
            preference: newPreference,
            histogram: this.createHistogram(newOutcomes, this.state.histogramHorizontalAxisMin, this.state.histogramHorizontalAxisMax).histogram
        })
    }

    // Show outcomes with the proper color.
    uitkomst(outcome: number, color?: string) {
        if (color === undefined) {
            return (
                <h4 style={{ textAlign: 'right' }}>
                    &euro;{Math.round(outcome).toLocaleString('nl-NL') + ',-'}
                </h4>
            );
        } else {
            return (
                <h5 style={{ textAlign: 'right', color: color }}>
                    &euro;{Math.round(outcome).toLocaleString('nl-NL') + ',-'}
                </h5>
            );
        }
    }

    // Fetch the respondent model from the storage account.
    savePreference = () => {
        console.log("Respondent requested...");

        // Update loading
        this.props.dispatch({
            type: 'respondent/loading',
            id: this.props.id
        } as IRespondentLoading);

        // Fetch the respondent model from the storage account.
        fetch(
            configData.API_URL + '/api/UpdateDistributionPreference/' + this.props.id + '/' + preferences[this.state.preference],
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(
                response => {
                    if (response.status === 200) {
                        // Succesful!
                        response.json().then((data: IRespondent) => {
                            console.log("Respondent received!", data);
                            // Dispatch update event.
                            this.props.dispatch({
                                type: 'respondent/set',
                                respondent: data
                            } as IRespondentSet);
                        });
                    } else {
                        // O-oh!
                        console.log("Respondent not received!", response);
                        // Dispatch update event.
                        this.props.dispatch({
                            type: 'respondent/failed',
                            respondent: {
                                id: this.props.id,

                                loading: false,
                                loaded: false,
                                failed: true
                            }
                        } as IRespondentFailed);
                    }
                },
                error => {
                    console.log("Respondent request failed.", error);
                }
            );
    }

    render() {
        // If we already have a preference continue...
        if (this.props.pensionBuilder.preference !== null) {
            return (<Redirect to="/verantwoordBeleggen" />)
        } else if (this.state.introduction >= 1) {
            let intro;
            switch (this.state.introduction) {
                case 1: intro =
                    <Row style={{ 'marginBottom': '10px' }}>
                        <div hidden={this.props.language !== 'nl'}>
                            <p>
                                In dit onderdeel krijgt u op een andere manier <em>mogelijke pensioenuitkomsten</em> te zien op basis van een bepaald risicoprofiel.
                                Kunt u zich vinden in de getoonde bedragen, staat u open voor <em>meer risico</em> of wilt u juist wat <em>meer zekerheid</em>?
                            </p>
                            <p>
                                Door naar rechts en naar links te klikken kunt u bepalen welke verdeling van risico en mogelijke pensioenuitkomst u het meeste aanspreekt. U kunt kiezen uit vijf verschillende profielen.
                            </p>
                        </div>
                        <div hidden={this.props.language !== 'en'}>
                            <p>
                                In this part you will be shown <em>possible pension outcomes</em> in a different manner, based on a determined risk profile.
                                Are you comfortable with the shown numbers, are you open to taking <em>more risk</em> or do you prefer <em>more certainty</em>?
                            </p>
                            <p>
                                By clicking to the left and right you can determine which distribution of risk and possible pension outcomes you like most. You can chose between five different profiles.
                            </p>
                        </div>
                    </Row>;
                    break;
                case 2: intro =
                    <Row>
                        <div>
                            <div hidden={this.props.language !== 'nl'}>
                                <p>
                                    Hieronder staat een voorbeeld van een risicoprofiel.
                                    In een risicoprofiel ziet u wat de kans is op een bepaald bedrag aan pensioen.
                                    Voor dit voorbeeld geldt dat het te verwachten pensioen niet minder dan <em>&euro; 750</em> is en niet meer dan <em>&euro; 1.250</em>.
                                </p>
                            </div>
                            <div hidden={this.props.language !== 'en'}>
                                <p>
                                    Below an example of a risk profile is shown.
                                    A risk profile shows the probability of a certain pension outcome.
                                    In this example the expected pension is no less than <em>&euro; 750</em> but also not more than <em>&euro; 1.250</em>
                                </p>
                            </div>
                            <Card style={{ 'marginTop': '10px' }}>
                                <Card.Body>
                                    <Chart
                                        chartType="Histogram"
                                        className="centered"
                                        chartLanguage={this.props.language}
                                        height={this.state.chartSize}
                                        style={{ 'minHeight': '250px' }}
                                        loader={<div>Loading Chart</div>}
                                        data={this.createHistogram(exampleDistribution.outcomes, Math.min(...exampleDistribution.outcomes), Math.max(...exampleDistribution.outcomes)).histogram}
                                        options={{
                                            legend: { position: 'none' },
                                            vAxis: {
                                                title: this.props.language === 'nl'
                                                    ? "Kans op uitkomst (percentage)"
                                                    : "Probability of outcome (percentage)",
                                                minValue: 0
                                            },
                                            hAxis: {
                                                title: this.props.language === 'nl'
                                                    ? "Hoogte maandelijkse pensioenuitkering (netto bedrag)"
                                                    : "Monthly pension (net outcome)",
                                                ticks: [750, 850, 950, 1050, 1150, 1250]
                                            }
                                        }}
                                    />
                                </Card.Body>
                            </Card>
                        </div>
                    </Row>
                    break;
                case 3: intro =
                    <Row>
                        <div>
                            <div hidden={this.props.language !== 'nl'}>
                                <p>
                                    Elk blokje is gelijk aan een kans van <em>1 op 100 (1%)</em>.
                                </p>
                                <p>
                                    Voor dit risicoprofiel geldt dat de <em style={{ 'color': 'green' }} >drie groene blokjes</em> pensioenuitkomsten voorstellen tussen de <em>&euro; 800</em> en <em>&euro; 850</em>.
                                    Oftewel, in <em>3 van de 100</em> gevallen zal de verwachte pensioenuitkomst in dit risicoprofiel tussen de <em>&euro; 800</em> en <em>&euro; 850</em> liggen.
                                </p>
                            </div>
                            <div hidden={this.props.language !== 'en'}>
                                <p>
                                    Every rectangle is equal to a probability of <em>1 in 100 (1%)</em>.
                                </p>
                                <p>
                                    For this risk profile the three green cubes represent pension outcomes between <em>&euro; 800</em> and <em>&euro; 850</em>.
                                    So in <em>3 out of a 100</em> times the expected pension outcome in this risk profile will lie between <em>&euro; 800</em> and <em>&euro; 850</em>.
                                </p>
                            </div>
                            <Card style={{ 'marginTop': '10px' }}>
                                <Card.Body>
                                    <Chart
                                        chartType="Histogram"
                                        chartLanguage={this.props.language}
                                        className="centered"
                                        height={this.state.chartSize}
                                        style={{ 'minHeight': '250px' }}
                                        loader={<div>Loading Chart</div>}
                                        data={this.createHistogram(exampleDistribution.outcomes, Math.min(...exampleDistribution.outcomes), Math.max(...exampleDistribution.outcomes)).histogram}
                                        options={{
                                            legend: { position: 'none' },
                                            vAxis: {
                                                title: this.props.language === 'nl'
                                                    ? "Kans op uitkomst (percentage)"
                                                    : "Probability of outcome (percentage)",
                                                minValue: 0
                                            },
                                            hAxis: {
                                                title: this.props.language === 'nl'
                                                    ? "Hoogte maandelijkse pensioenuitkering (netto bedrag)"
                                                    : "Monthly pension (net outcome)",
                                                ticks: [750, 850, 950, 1050, 1150, 1250]
                                            },
                                            tooltip: {
                                                trigger: 'selection',
                                                color: 'red'
                                            }
                                        }}

                                        chartEvents={[
                                            {
                                                eventName: "ready",
                                                callback({ chartWrapper }) {
                                                    console.log("Ready selection...");
                                                    var chart = chartWrapper.getChart() as any;
                                                    console.log(chart);
                                                    var selection: any[] = [];
                                                    for (var i = 2; i <= 4; i++) {
                                                        selection.push({ row: i });
                                                    }
                                                    chart.setSelection(selection);
                                                }
                                            }
                                        ]}
                                    />
                                </Card.Body>
                            </Card>
                        </div>
                    </Row>
                    break;
            }

            return (
                <div className="textDiv">
                    <div hidden={this.props.language !== 'nl'}>
                        <h1>Het risicoprofiel van uw voorkeur</h1>
                        <h5>Onderdeel 2 van 4</h5>
                    </div>
                    <div hidden={this.props.language !== 'en'}>
                        <h1>The risk profile of your preferences</h1>
                        <h5>Part 2 of 4</h5>
                    </div>
                    <Container>
                        {intro}
                        <Row>
                            {this.state.introduction > 1 && <Col>
                                <Button className="centered" onClick={() => this.backIntroduction()}>
                                    <span style={{ 'marginRight': '5px' }} className="icon fas fa-backward" /> Terug
                                </Button>
                            </Col>}
                            <Col>
                                <Button className="centered" onClick={() => this.nextIntroduction()}>
                                    {this.state.introduction < 3 ? "Verder" : "Start"}
                                    <span style={{ 'marginLeft': '5px' }} className="icon fas fa-forward" />
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        } else {
            let currentDistribution = this.props.pensionBuilder.distributions[preferences[this.state.preference]];

            return (
                <Container className="centered">
                    <Row style={{ 'marginBottom': '10px' }}>
                        <Col>
                        </Col>
                        <Col>
                            <Button
                                className="centered"
                                style={{ 'margin': '0 0 0 auto ' }}
                                onClick={() => this.showIntroduction(true)}
                            >
                                <span className="icon fas fa-info-circle" style={{ 'marginRight': '5px' }} />
                                {this.props.language === 'nl' ? "Terug naar uitleg" : "Show explanation"}
                            </Button>
                        </Col>
                    </Row>
                    <Row className="centered">

                        <div hidden={this.props.language !== 'nl'}>
                            <p>
                                U kunt nu kiezen tussen <em>vijf verschillende</em> risicoprofielen.
                                De bedragen links geven een versimpelde weergave van de grafiek rechts. Deze zijn toegevoegd voor het gemak.
                                Met de knoppen onderaan de pagina kunt aangeven of u open staat voor <em>meer zekerheid</em> (minder risico) of <em>meer risico</em> (minder zekerheid) en de vijf verschillende profielen bekijken.
                            </p>
                            <p>
                                Een profiel met meer risico leidt tot een hoger pensioen als het loopt zoals verwacht, maar ook tot een grotere spreiding van de mogelijke uitkomsten van uw pensioen.
                                Dit is zichtbaar doordat de grafiek breder en platter wordt.
                                Een profiel met meer zekerheid leidt tot een lager pensioen als het loopt zoals verwacht, maar ook tot een kleinere spreiding van de mogelijke uitkomsten van uw pensioen.
                                Dit is zichtbaar doordat de grafiek hoger en smaller wordt.
                            </p>
                            <p>
                                Tot slot geeft u uw uiteindelijke keuze door via de knop <em>ik kies voor dit profiel</em>.
                            </p>
                        </div>
                        <div hidden={this.props.language !== 'en'}>
                            <p>
                                You can now choose between <em>five different</em> risk profiles.
                                The numbers on the left show a simplified version of the graph on the right.
                                These are added for ease.
                                With the buttons on the bottom of the screen you can indicate whether you are open to <em>more certainty</em> (less risk) or <em>more risk</em> (less certainty) and you can view the five different profiles.
                            </p>
                            <p>
                                A profile with more risk results in a higher expected pension but this comes at the price of a higher spread in the pension outcomes.
                                This is visible in the widening and flattening of the graph.
                                A profile with more certainty results in a lower pension in expectation but also has a lower spread in the possible pension outcomes.
                                This is visible in the graph becoming more narrow and higher.
                            </p>
                            <p>
                                You can lock in your choice by pressing the <em>I prefer this profile</em> button.
                            </p>
                        </div>
                        <Card style={{ 'marginBottom': '10px', 'marginTop': '10px' }}>
                            <Card.Body style={{ 'padding': '0px' }}>
                                <Container>
                                    <Row>
                                        <Col lg={{ span: 9, order: "last" }} style={{ 'padding': '0px' }}>
                                            <Chart
                                                chartType="Histogram"
                                                chartLanguage={this.props.language}
                                                className="centered"
                                                height={this.state.chartSize}
                                                style={{
                                                    'margin': '0px',
                                                    'minHeight': '300px',
                                                    'maxHeight': '450px'
                                                }}
                                                loader={<div>Loading Chart</div>}
                                                data={this.state.histogram}
                                                options={{
                                                    legend: { position: 'none' },
                                                    vAxis: {
                                                        title: this.props.language === 'nl'
                                                            ? "Kans op uitkomst (percentage)"
                                                            : "Probability of outcome (percentage)",
                                                        minValue: 0
                                                    },
                                                    hAxis: {
                                                        title: this.props.language === 'nl'
                                                            ? "Hoogte maandelijkse pensioenuitkering (netto bedrag)"
                                                            : "Monthly pension (net outcome)",
                                                        minValue: this.state.histogramHorizontalAxisMin,
                                                        maxValue: this.state.histogramHorizontalAxisMax,
                                                        ticks: this.state.ticks
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <Col lg={{ span: 3, order: "first" }} style={{ 'marginTop': 'auto', 'marginBottom': 'auto' }}>
                                            <div style={{ 'fontSize': 'small' }}>
                                                {this.props.language === 'nl'
                                                    ? "Als het beter gaat"
                                                    : "If it goes better than expected"}
                                                {this.uitkomst(currentDistribution.good, 'green')}
                                            </div>
                                            <div>
                                                {this.props.language === 'nl'
                                                    ? "Als het loopt zoals verwacht"
                                                    : "If it goes as expected"}
                                                {this.uitkomst(currentDistribution.median)}
                                            </div>
                                            <div style={{ 'fontSize': 'small' }}>
                                                {this.props.language === 'nl'
                                                    ? "Als het minder gaat"
                                                    : "If it goes worse than expected"}
                                                {this.uitkomst(currentDistribution.bad, 'red')}
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                            <Card.Footer>
                                <Container>
                                    <Row>
                                        <Col md={{ span: 4, order: "first" }} style={{ 'marginTop': '5px', 'marginBottom': '5px' }}>
                                            <Button
                                                className="centered"
                                                disabled={this.state.preference == 0}
                                                onClick={() => this.changePreference(false)}>
                                                <span style={{ 'marginRight': '5px' }} className="icon fas fa-backward" />
                                                {this.props.language === 'nl'
                                                    ? "Meer zekerheid"
                                                    : "More certainty"}
                                            </Button>
                                        </Col>
                                        <Col md={{ span: 4 }} style={{ 'marginTop': '5px', 'marginBottom': '5px' }}>
                                            <Button
                                                className="centered"
                                                onClick={() => this.savePreference()}
                                            >
                                                {this.props.language === 'nl'
                                                    ? "Ik kies voor dit profiel"
                                                    : "I prefer this profile"}
                                            </Button>
                                        </Col>
                                        <Col md={{ span: 4 }} style={{ 'marginTop': '5px', 'marginBottom': '5px' }}>
                                            <Button
                                                className="centered"
                                                disabled={this.state.preference == preferences.length - 1}
                                                onClick={() => this.changePreference(true)}>
                                                {this.props.language === 'nl'
                                                    ? "Meer risico"
                                                    : "More risk"}
                                                <span style={{ 'marginLeft': '5px' }} className="icon fas fa-forward" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Footer>
                        </Card >
                    </Row>
                </Container >
            );
        }
    }
};

export default connect(state => state)(withRouter(PensionBuilderDisplay));