import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Pensioenproduct from "../components/pensioenproduct";
import configData from "../config.json";
import { IRespondent, IRespondentConnected } from "../respondent";
import { IRespondentFailed, IRespondentLoading, IRespondentSet } from "../respondentActions";

interface IHoltAndLauryDisplayState {
    introduction: number;
}

class HoltAndLauryDisplay extends React.Component<IRespondentConnected, IHoltAndLauryDisplayState> {
    constructor(props: IRespondentConnected) {
        super(props);

        // Always start with the introduction.
        // Regardless if it was already started.
        var currentHoltAndLaury = this.getCurrentHoltAndLaury();

        if (currentHoltAndLaury !== undefined && currentHoltAndLaury.step > 1) {
            this.state = {
                introduction: 0
            }
        } else {
            this.state = {
                introduction: 1
            }
        }
    }

    // By retrieving the Holt and Laury which has not been updated yet we have the active step.
    getCurrentHoltAndLaury = () => this.props.holtAndLauries.find(hl => hl.lastUpdatedOn === null);

    // On confirmation we no longer need to show the introduction.
    // On help we should be able to show it again.
    showIntroduction = (introduction: boolean) => {
        window.scrollTo(0, 0);
        this.setState({ introduction: introduction ? 1 : 0 });
    }

    backIntroduction = () => {
        window.scrollTo(0, 0);
        this.setState({ introduction: this.state.introduction - 1 });
    }

    nextIntroduction = () => {
        window.scrollTo(0, 0);
        var newIntro = this.state.introduction + 1;
        this.setState({ introduction: newIntro > 4 ? 0 : newIntro });
    }

    updatePreference(step: number, preference: string) {
        console.log("Respondent requested...");

        // Update loading
        this.props.dispatch({
            type: 'respondent/loading',
            id: this.props.id
        } as IRespondentLoading);

        // Fetch the respondent model from the storage account.
        fetch(
            configData.API_URL + '/api/UpdateHoltAndLaury/' + this.props.id + '/' + step + '/' + preference,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(
                response => {
                    if (response.status === 200) {
                        // Succesful!
                        response.json().then((data: IRespondent) => {
                            console.log("Respondent received!", data);
                            // Dispatch update event.
                            this.props.dispatch({
                                type: 'respondent/set',
                                respondent: data
                            } as IRespondentSet);
                        });
                    } else {
                        // O-oh!
                        console.log("Respondent not received!", response);
                        // Dispatch update event.
                        this.props.dispatch({
                            type: 'respondent/failed',
                            respondent: {
                                id: this.props.id,

                                loading: false,
                                loaded: false,
                                failed: true
                            }
                        } as IRespondentFailed);
                    }
                },
                error => {
                    console.log("Respondent request failed.", error);
                }
            );
    }

    render() {
        // Check what the current step is for Holt and Laury.
        var currentHoltAndLaury = this.getCurrentHoltAndLaury();

        // If all answers have ben already updated...
        // We can not find any and redirect to Pensionbuilder!
        if (currentHoltAndLaury === undefined) {
            return (<Redirect to='/pensionBuilder' />);
            // Otherwise, let's go!
        } else if (this.state.introduction >= 1) {
            let intro;
            switch (this.state.introduction) {
                case 1: intro =
                    <Row style={{ 'marginBottom': '10px' }}>
                        <div hidden={this.props.language !== 'nl'}>
                            <p>
                                In dit onderdeel maakt u <em>tien keer</em> een keuze tussen twee verschillende, fictieve pensioenen.
                                De twee pensioenen kennen elk een <em>hoge</em> en een <em>lage</em> uitkomst.
                            </p>
                            <p>
                                Met elke stap veranderen de kansen op een bepaalde pensioenuitkomst en geeft u aan welke verdeling van risico u het meeste aanspreekt: <em>A</em> of <em>B</em>.
                            </p>
                        </div>
                        <div hidden={this.props.language !== 'en'}>
                            <p>
                                In this part you will choose between two different <em>fictitious</em> options for a total of <em>ten times</em>.
                                The two options have a <em>high</em> and <em>low</em> pension outcome.
                            </p>
                            <p>
                                With every step the probabilities of the outcomes change and you indicate what distribution of risk you prefer: <em>A</em> of <em>B</em>.
                            </p>
                        </div>
                    </Row>;
                    break;
                case 2: intro =
                    <Row>
                        <div>
                            <p hidden={this.props.language !== 'nl'}>
                                Hieronder staat een voorbeeld van een fictief pensioen.
                                Dit product kent in het gunstige geval een <em>hoge uitkering</em> gelijk aan <em>&euro; 3.000</em>.
                                In het ongunstige geval kent het product een <em>lage uitkering</em> van slechts <em>&euro; 2.000</em>.
                            </p>
                            <p hidden={this.props.language !== 'en'}>
                                Below there is an example shown for a fictional pension.
                                For this product in the favorable scenario the <em>high outcome</em> equal <em>&euro; 3.000</em>.
                                In the unfavorable scenario the <em>low outcome</em> is equal to <em>&euro; 2.000</em>.
                            </p>
                            <Pensioenproduct
                                product="A"
                                language={this.props.language}
                                model={{
                                    probabilityHigh: 0.4,
                                    probabilityLow: 0.6,

                                    outcomeHigh: 3000,
                                    outcomeLow: 2000
                                }}
                            />
                        </div>
                    </Row >;
                    break;
                case 3: intro =
                    <Row>
                        <div>
                            <p hidden={this.props.language !== 'nl'}>
                                De kans dat het fictieve pensioen leidt tot een <em>hoge uitkering</em> is gelijk aan <em>40%</em>.
                                Deze kans is visueel vormgegeven in de grafiek door het <em style={{ 'color': 'green' }}>groene vlak</em>.
                            </p>
                            <p hidden={this.props.language !== 'en'}>
                                The probability the fictional pension yields the <em>high outcome</em> is equal to <em>40%</em>.
                                This probability is visualized in the graph by the <em style={{ 'color': 'green' }}>green area</em>.
                            </p>
                            <Pensioenproduct
                                product="A"
                                language={this.props.language}
                                colorHigh="green"
                                model={{
                                    probabilityHigh: 0.4,
                                    probabilityLow: 0.6,

                                    outcomeHigh: 3000,
                                    outcomeLow: 2000
                                }}
                            />
                        </div>
                    </Row>
                    break;
                case 4: intro =
                    <Row>
                        <div>
                            <p hidden={this.props.language !== 'nl'}>
                                De kans dat het fictieve pensioen leidt tot een <em>lage uitkering</em> is gelijk aan <em>60%</em>.
                                Deze kans is visueel vormgegeven in de grafiek door het <em style={{ 'color': 'orange' }}>oranje vlak</em>.
                            </p>
                            <p hidden={this.props.language !== 'en'}>
                                The probability the fictional pension yields the <em>low outcome</em> is equal to <em>60%</em>.
                                This probability is visualized in the graph by the <em style={{ 'color': 'orange' }}>orange area</em>.
                            </p>
                            <Pensioenproduct
                                product="A"
                                language={this.props.language}
                                colorLow="orange"
                                model={{
                                    probabilityHigh: 0.4,
                                    probabilityLow: 0.6,

                                    outcomeHigh: 3000,
                                    outcomeLow: 2000
                                }}
                            />
                        </div>
                    </Row>
                    break;
            }

            return (
                <div className="textDiv">
                    <div hidden={this.props.language !== 'nl'}>
                        <h1>Keuze tussen mogelijke pensioenuitkomsten</h1>
                        <h5>Onderdeel 1 van 4</h5>
                    </div>
                    <div hidden={this.props.language !== 'en'}>
                        <h1>Choosing different pension outcomes</h1>
                        <h5>Part 1 of 4</h5>
                    </div>
                    <Container>
                        {intro}
                        <Row>
                            {this.state.introduction > 1 && <Col>
                                <Button className="centered" onClick={() => this.backIntroduction()}>
                                    <span style={{ 'marginRight': '5px' }} className="icon fas fa-backward" />
                                    {this.props.language === 'nl' ? "Terug" : "Back"}
                                </Button>
                            </Col>}
                            <Col>
                                <Button className="centered" onClick={() => this.nextIntroduction()}>
                                    {this.state.introduction < 4 ? (this.props.language === 'nl' ? "Verder" : "Continue") : "Start"}
                                    <span style={{ 'marginLeft': '5px' }} className="icon fas fa-forward" />
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        } else {
            // Store step to prevent issues later on with the button.
            var step = currentHoltAndLaury.step;

            return (
                <Container>
                    <Row style={{ 'marginBottom': '10px' }}>
                        <Col>
                            <h5 style={{ 'margin': '0px auto' }} hidden={this.props.language !== 'nl'}>
                                Stap {step} van {this.props.holtAndLauries.length}
                            </h5>
                            <h5 style={{ 'margin': '0px auto' }} hidden={this.props.language !== 'en'}>
                                Step {step} of {this.props.holtAndLauries.length}
                            </h5>
                        </Col>
                        <Col>
                            <Button
                                className="centered"
                                style={{ 'margin': '0 0 0 auto ' }}
                                onClick={() => this.showIntroduction(true)}
                            >
                                <span className="icon fas fa-info-circle" style={{ 'marginRight': '5px' }} />
                                {this.props.language === 'nl' ? "Terug naar uitleg" : "Show explanation"}
                            </Button>
                        </Col>
                    </Row>
                    {step == 1 && <Row style={{ 'marginBottom': '10px' }}>
                        <Col>
                            <div hidden={this.props.language !== 'nl'}>
                                <p>
                                    Het onderzoek begint nu echt.
                                </p>
                                <p>
                                    De twee fictieve pensioenen <em>A</em> en <em>B</em> zijn hieronder weergeven.
                                    Bij een hoge uitkomst is pensioen <em>A</em> gelijk aan <em>&euro; {Math.round(currentHoltAndLaury.outcomeHighA).toLocaleString('nl-NL')}</em>. Voor pensioen <em>B</em> geldt een hoge uitkomst van <em>&euro; {currentHoltAndLaury.outcomeHighB.toLocaleString('nl-NL')}</em>.
                                    De kans op een hoge uitkomst is voor beide pensioenen gelijk aan <em>{(100 * currentHoltAndLaury.probabilityHigh).toFixed(0)}%</em>.
                                </p>
                                <p>
                                    De kans op een lage uitkomst is voor beide pensioenen gelijk aan <em>{(100 * currentHoltAndLaury.probabilityLow).toFixed(0)}%</em>.
                                    Voor pensioen <em>A</em> is de lage uitkomst gelijk aan <em>&euro; {Math.round(currentHoltAndLaury.outcomeLowA).toLocaleString('nl-NL')}</em> en voor pensioen <em>B</em> geldt een lage uitkomst van <em>&euro; {currentHoltAndLaury.outcomeLowB.toLocaleString('nl-NL')}</em>.
                                </p>
                                <p>
                                    Gegeven deze pensioenen, welk pensioen heeft dan <em>uw voorkeur</em>?
                                </p>
                            </div>
                            <div hidden={this.props.language !== 'en'}>
                                <p>
                                    The survey starts now.
                                </p>
                                <p>
                                    The two fictional pensions <em>A</em> and <em>B</em> are shown below.
                                    In the high outcome pension <em>A</em> equals <em>&euro; {Math.round(currentHoltAndLaury.outcomeHighA).toLocaleString('nl-NL')}</em>. Pension <em>B</em> is equal to <em>&euro; {currentHoltAndLaury.outcomeHighB.toLocaleString('nl-NL')}</em>. in the high oucome.
                                    The probability of the high outcome is <em>{(100 * currentHoltAndLaury.probabilityHigh).toFixed(0)}%</em> for both pensions.
                                </p>
                                <p>
                                    The probability of a lower outcome is equal to <em>{(100 * currentHoltAndLaury.probabilityLow).toFixed(0)}%</em> for both pensions.
                                    For pension <em>A</em> the lower outcome is equal to <em>&euro; {Math.round(currentHoltAndLaury.outcomeLowA).toLocaleString('nl-NL')}</em> and for pension <em>B</em> the low outcome equals <em>&euro; {currentHoltAndLaury.outcomeLowB.toLocaleString('nl-NL')}</em>.
                                </p>
                                <p>
                                    Given these pensions which one do you <em>prefer</em>?
                                </p>
                            </div>
                        </Col>
                    </Row>}
                    {step >= 2 && <Row style={{ 'marginBottom': '10px' }}>
                        <Col>
                            <div hidden={this.props.language !== 'nl'}>
                                <p>
                                    De kans op een hoge uitkomst is toegenomen en is nu gelijk aan <em>{(100 * currentHoltAndLaury.probabilityHigh).toFixed(0)}%</em>. Tegelijkertijd is de kans op een lage uitkomst afgenomen tot <em>{(100 * currentHoltAndLaury.probabilityLow).toFixed(0)}%</em>.
                                </p>
                                <p>
                                    Welk pensioen heeft nu <em>uw voorkeur</em>?
                                </p>
                            </div>
                            <div hidden={this.props.language !== 'en'}>
                                <p>
                                    The probability of the high outcome has increased to <em>{(100 * currentHoltAndLaury.probabilityHigh).toFixed(0)}%</em>. At the same time the probability of the low outcome decreased to <em>{(100 * currentHoltAndLaury.probabilityLow).toFixed(0)}%</em>.
                                </p>
                                <p>
                                    Which pension do you <em>prefer</em> now?
                                </p>
                            </div>
                        </Col>
                    </Row>}
                    <Row>
                        <Col>
                            <Pensioenproduct
                                product="A"
                                language={this.props.language}
                                model={{
                                    probabilityHigh: currentHoltAndLaury.probabilityHigh,
                                    probabilityLow: currentHoltAndLaury.probabilityLow,

                                    outcomeHigh: Math.round(currentHoltAndLaury.outcomeHighA),
                                    outcomeLow: Math.round(currentHoltAndLaury.outcomeLowA)
                                }}
                                onPreferenceClicked={() => this.updatePreference(step, 'A')}
                            />
                        </Col>
                        <Col>
                            <Pensioenproduct
                                product="B"
                                language={this.props.language}
                                model={{
                                    probabilityHigh: currentHoltAndLaury.probabilityHigh,
                                    probabilityLow: currentHoltAndLaury.probabilityLow,

                                    outcomeHigh: Math.round(currentHoltAndLaury.outcomeHighB),
                                    outcomeLow: Math.round(currentHoltAndLaury.outcomeLowB)
                                }}
                                onPreferenceClicked={() => this.updatePreference(step, 'B')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className="centered" onClick={() => this.updatePreference(step, 'A')}>
                                {this.props.language === 'nl' ? "Ik kies voor Pensioen A" : "I prefer Pension A"}
                            </Button>
                        </Col>
                        <Col>
                            <Button className="centered" onClick={() => this.updatePreference(step, 'B')}>
                                {this.props.language === 'nl' ? "Ik kies voor Pensioen B" : "I prefer Pension B"}
                            </Button>
                        </Col>
                    </Row>
                </Container >
            );
        }
    }
};

export default connect(state => state)(HoltAndLauryDisplay);