import React from "react";
import { Form } from "react-bootstrap";

interface IRangeSliderProperties {
    id: string;

    min: number;
    max: number;
    initialValue?: number;

    valueDecorator(value: number): string;
}

interface IRangeSliderState {
    value: number;
}

export default class RangeSlider extends React.Component<IRangeSliderProperties, IRangeSliderState> {
    constructor(props: IRangeSliderProperties) {
        super(props);

        this.state = {
            value: this.props.initialValue ?? (this.props.min + this.props.max) / 2
        };
    }

    render() {
        return (
            <div>
                <Form.Control
                    type="range"
                    id={this.props.id}
                    min={this.props.min}
                    max={this.props.max}
                    value={this.state.value}
                    onChange={(event: any) => this.setState({ value: event.target.value })}
                />
                <Form.Label className="centered" style={{ 'textAlign': 'center' }}>
                    <h5>
                        {this.props.valueDecorator(this.state.value)}
                    </h5>
                </Form.Label>
            </div>
        );
    }
}